import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { Grid, makeStyles } from '@material-ui/core';
import { AppContext } from '../context/AppContext';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import mao from '../asset/image/MaosParaCoeta.png';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100vh',
    textAlign: 'center',
  },
  captureArea: {
    border: '2px dashed #000',
    width: '320px',
    height: '360px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '10px auto',
    borderRadius: '10px',
    position: 'relative',
  },
  areaEscolha: {
    width: '600px',
    height: '300px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '10px auto',
    borderRadius: '10px',
    position: 'relative',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: 'none',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: '8px',
    maxWidth: '500px',
    width: '100%',
    textAlign: 'center',
  },
  captureText: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
    color: '#000',
    fontSize: '1.5rem',
    zIndex: 2,
  },
  handImage: {
    position: 'relative',
    width: '100%',
    zIndex: 1,
  },
  selectionArea: {
    position: 'absolute',
    border: '2px solid blue',
    borderRadius: '50%',
    opacity: 0.7,
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'blue',
    fontWeight: 'bold',
    width: '20px',
    height: '20px',
    zIndex: 3,
  },
  selected: {
    border: '2px solid green',
    color: 'white',
    backgroundColor: 'green',
  },
  imageContainer: {
    display: 'flex',
    justifyContent: 'center',
    height: '95%',
    objectFit: 'contain',
    zIndex: '3',
  },
  button: {
    color: 'white',
    '&.capturar': {
      backgroundColor: 'blue',
    },
    '&.prosseguir': {
      backgroundColor: 'green',
    },
  },
}));

const CaptureFuncionarioPage = () => {
  const { capturedImage, setCapturedImage, setTipoRegistro, selectedFinger, setSelectedFinger } = useContext(AppContext);
  const classes = useStyles();
  const navigate = useNavigate();
  const [instructions, setInstructions] = useState('Coloque o dedo sobre o leitor de impressão digital!');
  const [open, setOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [imageSrc, setImageSrc] = useState(''); // Armazena a imagem recebida via WebSocket
  const [socket, setSocket] = useState(null); // WebSocket
  const [isConnected, setIsConnected] = useState(false); // Verifica a conexão WebSocket
  const [captureStarted, setCaptureStarted] = useState(false); // Estado da captura

  // Token fixo fornecido
  const token = 'f808817e93b178a17975e6b4605b8e2a960cac6dad2ece0d0dd5e83f94fd40aa';

  // Função para conectar ao WebSocket automaticamente ao carregar a página
  useEffect(() => {
    const newSocket = new WebSocket(`ws://localhost:5000/ws?token=${token}`);

    newSocket.onopen = () => {
      setIsConnected(true);
    };

    newSocket.onclose = () => {
      setIsConnected(false);
    };

    newSocket.onerror = (error) => {
      // Exibe uma mensagem genérica sem expor o endereço WebSocket completo
      console.error("Erro no WebSocket: Conexão falhou. Por favor, tente novamente.");
      setIsConnected(false);
    };

    newSocket.onmessage = (event) => {
      const data = JSON.parse(event.data);
      if (data.image && data.image.length > 0) {
        setImageSrc(`data:image/jpg;base64,${data.image}`);
      } else {
        setModalMessage('Erro: Imagem base64 está vazia.');
        console.error('Imagem recebida, mas a string base64 está vazia.');
      }
    };

    setSocket(newSocket);

    return () => {
      // Limpar a conexão ao sair da página
      if (newSocket) newSocket.close();
    };
  }, [token]);

  // Função para enviar comandos via WebSocket
  const sendWebSocketCommand = (command) => {
    if (socket && socket.readyState === WebSocket.OPEN) {
      socket.send(JSON.stringify({ command }));
      setModalMessage(`Comando ${command} enviado via WebSocket.`);
    } else {
      setModalMessage('Erro: WebSocket não está conectado.');
    }
  };

  const handleCaptureClick = () => {
    if (captureStarted) {
      // Se a captura já foi iniciada, para a captura e vai para a próxima página
      proceedToNextPage();
    } else {
      // Iniciar captura
      sendWebSocketCommand('start_capture');
      setCaptureStarted(true);
      setInstructions('Captura iniciada. Coloque o dedo no leitor.');
    }
  };

  const proceedToNextPage = () => {
    if (imageSrc) {
      sendWebSocketCommand('stop_capture');
      setCapturedImage(imageSrc);
      setTipoRegistro(1);
      navigate('/envio-funcionario');
    } else {
      setModalMessage('Por favor, capture uma impressão digital antes de prosseguir.');
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const selectFinger = (id) => {
    setSelectedFinger(id);
    setInstructions(`Dedo ${id} selecionado. Clique em capturar e coloque o dedo sobre o leitor de impressão digital!`);
  };

  const fingers = [
    { id: 0, label: '0', x: '6%', y: '28%' },
    { id: 8, label: '8', x: '13%', y: '4%' },
    { id: 6, label: '6', x: '25%', y: '-7%' },
    { id: 4, label: '4', x: '35%', y: '-1%' },
    { id: 2, label: '2', x: '47%', y: '36%' },
    { id: 1, label: '1', x: '52%', y: '36%' },
    { id: 3, label: '3', x: '62%', y: '-1%' },
    { id: 5, label: '5', x: '73%', y: '-7%' },
    { id: 7, label: '7', x: '86%', y: '4%' },
    { id: 9, label: '9', x: '92%', y: '28%' },
  ];

  return (
    <div className={classes.container}>
      <h1>{instructions}</h1>
      <div className={classes.areaEscolha}>
        <img src={mao} alt="Imagem das Mãos" className={classes.handImage} />
        {fingers.map((finger) => (
          <div
            key={finger.id}
            className={`${classes.selectionArea} ${selectedFinger === finger.id ? classes.selected : ''}`}
            style={{ left: finger.x, top: finger.y }}
            onClick={() => selectFinger(finger.id)}
          >
            {finger.label}
          </div>
        ))}
      </div>
      <div className={classes.captureArea}>
        {imageSrc ? (
          <img src={imageSrc} alt="Imagem capturada" className={classes.imageContainer} />
        ) : (
          <div className={classes.captureText}>
            Por favor, confirme se a leitura da biometria está correta antes de continuar.
          </div>
        )}
      </div>
      <Grid container justifyContent="center" style={{ maxWidth: '600px', gap: '1rem', zIndex: 10, margin: '25px' }}>
        <Grid item xs={10} sm={5}>
          <Button
            fullWidth
            variant="contained"
            size="large"
            className={`${classes.button} ${captureStarted ? 'prosseguir' : 'capturar'}`}
            onClick={handleCaptureClick}
            disabled={!isConnected}
          >
            {captureStarted ? 'Prosseguir' : 'Capturar'}
          </Button>
        </Grid>
      </Grid>
      <Modal className={classes.modal} open={open} onClose={handleClose} closeAfterTransition BackdropComponent={Backdrop} BackdropProps={{ timeout: 500 }}>
        <Fade in={open}>
          <div className={classes.paper}>
            <h2>Aviso</h2>
            <div>{modalMessage}</div>
            <Button onClick={handleClose} variant="contained">
              Fechar
            </Button>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

export default CaptureFuncionarioPage;
