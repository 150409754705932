// import React, { useState, useEffect, useContext } from 'react';
// import { useNavigate } from 'react-router-dom';
// import Button from '@material-ui/core/Button';
// import { Grid, makeStyles } from '@material-ui/core';
// import Modal from '@material-ui/core/Modal';
// import Backdrop from '@material-ui/core/Backdrop';
// import Fade from '@material-ui/core/Fade';
// import { AppContext } from '../context/AppContext';
// import mao from '../asset/image/MaosParaCoeta.png'; // Caminho atualizado para a imagem de referência
// import { FingerprintSdk } from '../fingerprint_reader/api/sdk_mod';

// const useStyles = makeStyles((theme) => ({
//   container: {
//     display: 'flex',
//     flexDirection: 'column',
//     alignItems: 'center',
//     justifyContent: 'center',
//     minHeight: '100vh',
//     textAlign: 'center',
//   },
//   botaoCapturar: {
//     [theme.breakpoints.down('xs')]: {
//       paddingTop: '11px',
//       paddingBottom: '11px',
//     },
//   },
//   captureArea: {
//     border: '2px dashed #000',
//     width: '320px', // Ajuste para o tamanho da nova imagem
//     height: '360px', // Ajuste para o tamanho da nova imagem
//     display: 'flex',
//     justifyContent: 'center',
//     alignItems: 'center',
//     margin: '10px auto',
//     borderRadius: '10px',
//     position: 'relative',
//   },
//   areaEscolha: {
//     width: '600px', // Ajuste para o tamanho da nova imagem
//     height: '300px', // Ajuste para o tamanho da nova imagem
//     display: 'flex',
//     justifyContent: 'center',
//     alignItems: 'center',
//     margin: '10px auto',
//     borderRadius: '10px',
//     position: 'relative',
//   },
//   modal: {
//     display: 'flex',
//     alignItems: 'center',
//     justifyContent: 'center',
//   },
//   paper: {
//     backgroundColor: theme.palette.background.paper,
//     border: 'none',
//     boxShadow: theme.shadows[5],
//     padding: theme.spacing(2, 4, 3),
//     borderRadius: '8px',
//     maxWidth: '500px',
//     width: '100%',
//     textAlign: 'center',
//   },
//   modalTitle: {
//     marginBottom: theme.spacing(2),
//     fontSize: '1.5rem',
//     fontWeight: 'bold',
//   },
//   modalContent: {
//     marginBottom: theme.spacing(2),
//   },
//   modalActions: {
//     display: 'flex',
//     justifyContent: 'space-between',
//   },
//   captureText: {
//     position: 'absolute',
//     top: '50%',
//     left: '50%',
//     transform: 'translate(-50%, -50%)',
//     textAlign: 'center',
//     color: '#000',
//     fontSize: '1.5rem',
//     zIndex: 2, // Garante que o texto esteja acima da imagem das mãos
//   },
//   handImage: {
//     position: 'relative',
//     width: '100%',
//     zIndex: 1, // Garante que a imagem esteja abaixo das bolinhas de seleção
//   },
//   selectionArea: {
//     position: 'absolute',
//     border: '2px solid blue',
//     borderRadius: '50%',
//     opacity: 0.7,
//     cursor: 'pointer',
//     display: 'flex',
//     justifyContent: 'center',
//     alignItems: 'center',
//     color: 'blue',
//     fontWeight: 'bold',
//     width: '20px',
//     height: '20px',
//     zIndex: 3, // Garante que as bolinhas de seleção estejam acima da imagem das mãos
//   },
//   selected: {
//     border: '2px solid green',
//     color: 'white',
//     backgroundColor: 'green',
//   },
//   imageContainer: {
//     display: 'flex',
//     justifyContent: 'center',
//     height: '95%',
//     objectFit: 'contain',
//     zIndex: '3',
//   },
//   instructionsText: {
//     position: 'absolute',
//     top: '30px',
//     width: '100%',
//     textAlign: 'center',
//     color: '#333', // Cor melhor para contraste
//     fontSize: '1.5rem', // Tamanho de fonte maior
//     zIndex: 4, // Garantir que o texto esteja acima de tudo
//   },
//   button: {
//     color: 'white',
//     '&.capturar': {
//       backgroundColor: 'blue', // Cor azul para o botão "Capturar"
//       color: 'white',
//     },
//     '&.prosseguir': {
//       backgroundColor: 'green', // Cor verde para o botão "Prosseguir"
//       color: 'white',
//     },
//   },
//   closeButton: {
//     backgroundColor: 'red',
//     color: 'white',
//     '&:hover': {
//       backgroundColor: 'darkred',
//     },
//   },
//   downloadButton: {
//     backgroundColor: 'blue',
//     color: 'white',
//     '&:hover': {
//       backgroundColor: 'darkblue',
//     },
//   },
// }));

// const CapturePage = () => {
//   const { capturedImage, setCapturedImage, selectedFinger, setSelectedFinger, setTipoRegistro } = useContext(AppContext);
//   const classes = useStyles();
//   const navigate = useNavigate();
//   const [deviceId, setDeviceId] = useState('');
//   const [instructions, setInstructions] = useState('Coloque o dedo sobre o leitor de impressão digital!');
//   const [Fingerprint, setFingerprint] = useState(null);
//   const [open, setOpen] = useState(false);
//   const [modalMessage, setModalMessage] = useState('');
//   const [captureStarted, setCaptureStarted] = useState(false);
//   const [showDownloadLink, setShowDownloadLink] = useState(false);

//   useEffect(() => {
//     // Redireciona para a página de captura de imagem caso seja um dispositivo móvel
//     const userAgent = navigator.userAgent || navigator.vendor || window.opera;
//     if (/android|iPad|iPhone|iPod/i.test(userAgent)) {
//       navigate('/capture_image_aluno'); // Redireciona para a página de captura via imagem
//     }
//   }, [navigate]);

//   useEffect(() => {
//     const initFingerprintSdk = async () => {
//       try {
//         ////console.log('Inicializando SDK de impressão digital...');
//         const sdk = new FingerprintSdk();
//         setFingerprint(sdk);
//         ////console.log('SDK inicializado:', sdk);
//         const devices = await sdk.getDeviceList();
//         if (devices.length > 0) {
//           setDeviceId(devices[0]);
//           setInstructions('Leitor Biométrico Conectado!');
//         } else {
//           setShowDownloadLink(true);
//           setInstructions('Nenhum dispositivo de leitura de impressão digital encontrado.');
//           setModalMessage('Nenhum dispositivo de leitura de impressão digital encontrado. Por favor, conecte o leitor e instale o software necessário.');
//           setOpen(true);
//         }
//       } catch (error) {
//         console.error('Erro ao inicializar o SDK de impressão digital:', error);
//         setInstructions('Erro ao inicializar o SDK de impressão digital.');
//       }
//     };

//     initFingerprintSdk();
//   }, []);

//   useEffect(() => {
//     handleRetake();
//   },);

//   const handleRetake = () => {
//     setCapturedImage(null);
//     setInstructions('Vamos lá, prepare-se para começar.');
//   };

//   const startCapturing = async () => {
//     if (selectedFinger < 0 || selectedFinger > 9) {
//       setModalMessage('Por favor, selecione um dedo antes de iniciar a captura.');
//       setOpen(true);
//       return;
//     }

//     setInstructions('Captura iniciada. Coloque o dedo no leitor.');
//     if (Fingerprint) {
//       setCaptureStarted(true);
//       try {
//         const imageSrc = await Fingerprint.startCapture();

//         if (imageSrc) {
//           sessionStorage.setItem("imageSrc", imageSrc);
//           setCapturedImage(imageSrc); // Atualiza o contexto com a imagem capturada
//         } else {
//           setInstructions('Não foi possível capturar a impressão digital. Tente novamente.');
//         }
//       } catch (error) {
//         console.error('Erro durante a captura:', error);
//         setInstructions('Erro durante a captura. Tente novamente.');
//       }
//     } else {
//       setInstructions('SDK não inicializado. Não é possível iniciar a captura.');
//     }
//   };

//   const proceedToNextPage = () => {
//     const imageSrc = sessionStorage.getItem("imageSrc");
//     if (imageSrc) {
//       setCapturedImage(imageSrc);
//       setTipoRegistro(1);
//       navigate('/envio'); // Redireciona para a próxima página
//     } else {
//       setModalMessage('Por favor, capture uma impressão digital antes de prosseguir.');
//       setOpen(true); // Abre o modal de aviso
//     }
//   };

//   const handleClose = () => {
//     setOpen(false);
//   };

//   const handleCaptureViaImage = () => {
//     // Implementar lógica para captura via imagem
//     navigate('/capture_image_aluno'); // Redireciona para a página de captura via imagem
//   };

//   const fingers = [
//     { id: 0, label: '0', x: '6%', y: '28%' },  // Dedo mínimo esquerdo
//     { id: 8, label: '8', x: '13%', y: '4%' },   // Dedo anelar esquerdo
//     { id: 6, label: '6', x: '25%', y: '-7%' },  // Dedo médio esquerdo
//     { id: 4, label: '4', x: '35%', y: '-1%' }, // Dedo indicador esquerdo
//     { id: 2, label: '2', x: '47%', y: '36%' }, // Polegar esquerdo
//     { id: 1, label: '1', x: '52%', y: '36%' }, // Polegar direito
//     { id: 3, label: '3', x: '62%', y: '-1%' }, // Dedo indicador direito
//     { id: 5, label: '5', x: '73%', y: '-7%' },  // Dedo médio direito
//     { id: 7, label: '7', x: '86%', y: '4%' },  // Dedo anelar direito
//     { id: 9, label: '9', x: '92%', y: '28%' } // Dedo mínimo direito
//   ];

//   const selectFinger = (id) => {
//     setSelectedFinger(id);
//     setInstructions(`Dedo ${id} selecionado. Clique em capturar e coloque o dedo sobre o leitor de impressão digital!`);
//   };

//   return (
//     <div className={classes.container}>
//       <h1 className={classes.instructionsText}>{instructions}</h1>
//       <div className={classes.areaEscolha}>
//         <img
//           src={mao}
//           alt="Imagem das Mãos"
//           className={classes.handImage}
//         />
//         {fingers.map(finger => (
//           <div
//             key={finger.id}
//             className={`${classes.selectionArea} ${selectedFinger === finger.id ? classes.selected : ''}`}
//             style={{ left: finger.x, top: finger.y }}
//             onClick={() => selectFinger(finger.id)}
//           >
//             {finger.label}
//           </div>
//         ))}
//       </div>
//       <div className={classes.captureArea}>
//         <div className={classes.imageContainer} id="imagediv">
//           {capturedImage && <img src={capturedImage} alt="Captura de impressão digital" />}
//         </div>
//         <div className={classes.captureText}>Por favor, confirme se a leitura da biometria está correta antes de continuar.</div>
//       </div>
//       <Grid container justifyContent='center' style={{ maxWidth: "600px", gap: '1rem', zIndex: 10, margin: '25px' }}>
//         <Grid item xs={10} sm={5}>
//           <Button
//             fullWidth
//             variant="contained"
//             size='large'
//             className={`${classes.button} ${captureStarted ? 'prosseguir' : 'capturar'}`}
//             onClick={captureStarted ? proceedToNextPage : startCapturing}
//           >
//             {captureStarted ? 'Prosseguir' : 'Capturar'}
//           </Button>
//         </Grid>
//       </Grid>
//       <Modal
//         className={classes.modal}
//         open={open}
//         onClose={handleClose}
//         closeAfterTransition
//         BackdropComponent={Backdrop}
//         BackdropProps={{
//           timeout: 500,
//         }}
//       >
//         <Fade in={open}>
//           <div className={classes.paper}>
//             <h2 className={classes.modalTitle}>Aviso</h2>
//             <div className={classes.modalContent}>
//               <p>{modalMessage}</p>
//               {showDownloadLink && (
//                 <div>
//                   <p>Caso já possua leitor conectado, instale o software necessário clicando no link abaixo, e atualize a pagina</p>
//                   <a href={`${process.env.PUBLIC_URL}/digitalPersonaClient.Setup64.exe`} download="digitalPersonaClient.Setup64.exe">
//                     Download do Software
//                   </a>
//                 </div>
//               )}
//             </div>
//             <div className={classes.modalActions}>
//               <Button onClick={handleClose} variant="contained" className={classes.closeButton}>
//                 Fechar
//               </Button>
//               {/* {showDownloadLink && ( */}
//                 <Button onClick={handleCaptureViaImage} variant="contained" className={classes.downloadButton}>
//                   Capturar Foto
//                 </Button>
//               {/* // )} */}
//             </div>
//           </div>
//         </Fade>
//       </Modal>
//     </div>
//   );
// };

// export default CapturePage;
import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { Grid, makeStyles } from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { AppContext } from '../context/AppContext';
import mao from '../asset/image/MaosParaCoeta.png'; // Caminho atualizado para a imagem de referência

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100vh',
    textAlign: 'center',
  },
  captureArea: {
    border: '2px dashed #000',
    width: '320px',
    height: '360px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '10px auto',
    borderRadius: '10px',
    position: 'relative',
  },
  areaEscolha: {
    width: '600px',
    height: '300px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '10px auto',
    borderRadius: '10px',
    position: 'relative',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: 'none',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: '8px',
    maxWidth: '500px',
    width: '100%',
    textAlign: 'center',
  },
  captureText: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
    color: '#000',
    fontSize: '1.5rem',
    zIndex: 2,
  },
  handImage: {
    position: 'relative',
    width: '100%',
    zIndex: 1,
  },
  selectionArea: {
    position: 'absolute',
    border: '2px solid blue',
    borderRadius: '50%',
    opacity: 0.7,
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'blue',
    fontWeight: 'bold',
    width: '20px',
    height: '20px',
    zIndex: 3,
  },
  selected: {
    border: '2px solid green',
    color: 'white',
    backgroundColor: 'green',
  },
  imageContainer: {
    display: 'flex',
    justifyContent: 'center',
    height: '95%',
    objectFit: 'contain',
    zIndex: '3',
  },
  button: {
    color: 'white',
    '&.capturar': {
      backgroundColor: 'blue',
    },
    '&.prosseguir': {
      backgroundColor: 'green',
    },
  },
}));

const CapturePage = () => {
  const { capturedImage, setCapturedImage, selectedFinger, setSelectedFinger, setTipoRegistro } = useContext(AppContext);
  const classes = useStyles();
  const navigate = useNavigate();
  const [instructions, setInstructions] = useState('Coloque o dedo sobre o leitor de impressão digital!');
  const [open, setOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [imageSrc, setImageSrc] = useState('');
  const [socket, setSocket] = useState(null);
  const [isConnected, setIsConnected] = useState(false);
  const [captureStarted, setCaptureStarted] = useState(false);

  const token = 'f808817e93b178a17975e6b4605b8e2a960cac6dad2ece0d0dd5e83f94fd40aa';

  // Conectar ao WebSocket automaticamente ao carregar a página
  useEffect(() => {
    const newSocket = new WebSocket(`ws://localhost:5000/ws?token=${token}`);

    newSocket.onopen = () => {
      setIsConnected(true);
    };

    newSocket.onclose = () => {
      setIsConnected(false);
    };

    newSocket.onerror = (error) => {
      console.error("Erro no WebSocket: Conexão falhou. Por favor, tente novamente.");
      setIsConnected(false);
    };

    newSocket.onmessage = (event) => {
      const data = JSON.parse(event.data);
      if (data.image && data.image.length > 0) {
        setImageSrc(`data:image/jpg;base64,${data.image}`);
      } else {
        setModalMessage('Erro: Imagem base64 está vazia.');
        console.error('Imagem recebida, mas a string base64 está vazia.');
      }
    };

    setSocket(newSocket);

    return () => {
      if (newSocket) newSocket.close();
    };
  }, [token]);

  const sendWebSocketCommand = (command) => {
    if (socket && socket.readyState === WebSocket.OPEN) {
      socket.send(JSON.stringify({ command }));
      setModalMessage(`Comando ${command} enviado via WebSocket.`);
    } else {
      setModalMessage('Erro: WebSocket não está conectado.');
    }
  };

  const handleCaptureClick = () => {
    if (captureStarted) {
      proceedToNextPage();
    } else {
      sendWebSocketCommand('start_capture');
      setCaptureStarted(true);
      setInstructions('Captura iniciada. Coloque o dedo no leitor.');
    }
  };

  const proceedToNextPage = () => {
    if (imageSrc) {
      sendWebSocketCommand('stop_capture');
      setCapturedImage(imageSrc);
      setTipoRegistro(1);
      navigate('/envio');
    } else {
      setModalMessage('Por favor, capture uma impressão digital antes de prosseguir.');
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const selectFinger = (id) => {
    setSelectedFinger(id);
    setInstructions(`Dedo ${id} selecionado. Clique em capturar e coloque o dedo sobre o leitor de impressão digital!`);
  };

  const fingers = [
    { id: 0, label: '0', x: '6%', y: '28%' },
    { id: 8, label: '8', x: '13%', y: '4%' },
    { id: 6, label: '6', x: '25%', y: '-7%' },
    { id: 4, label: '4', x: '35%', y: '-1%' },
    { id: 2, label: '2', x: '47%', y: '36%' },
    { id: 1, label: '1', x: '52%', y: '36%' },
    { id: 3, label: '3', x: '62%', y: '-1%' },
    { id: 5, label: '5', x: '73%', y: '-7%' },
    { id: 7, label: '7', x: '86%', y: '4%' },
    { id: 9, label: '9', x: '92%', y: '28%' },
  ];

  return (
    <div className={classes.container}>
      <h1>{instructions}</h1>
      <div className={classes.areaEscolha}>
        <img src={mao} alt="Imagem das Mãos" className={classes.handImage} />
        {fingers.map((finger) => (
          <div
            key={finger.id}
            className={`${classes.selectionArea} ${selectedFinger === finger.id ? classes.selected : ''}`}
            style={{ left: finger.x, top: finger.y }}
            onClick={() => selectFinger(finger.id)}
          >
            {finger.label}
          </div>
        ))}
      </div>
      <div className={classes.captureArea}>
        {imageSrc ? (
          <img src={imageSrc} alt="Imagem capturada" className={classes.imageContainer} />
        ) : (
          <div className={classes.captureText}>
            Por favor, confirme se a leitura da biometria está correta antes de continuar.
          </div>
        )}
      </div>
      <Grid container justifyContent="center" style={{ maxWidth: '600px', gap: '1rem', zIndex: 10, margin: '25px' }}>
        <Grid item xs={10} sm={5}>
          <Button
            fullWidth
            variant="contained"
            size="large"
            className={`${classes.button} ${captureStarted ? 'prosseguir' : 'capturar'}`}
            onClick={handleCaptureClick}
            disabled={!isConnected}
          >
            {captureStarted ? 'Prosseguir' : 'Capturar'}
          </Button>
        </Grid>
      </Grid>
      <Modal className={classes.modal} open={open} onClose={handleClose} closeAfterTransition BackdropComponent={Backdrop} BackdropProps={{ timeout: 500 }}>
        <Fade in={open}>
          <div className={classes.paper}>
            <h2>Aviso</h2>
            <div>{modalMessage}</div>
            <Button onClick={handleClose} variant="contained">
              Fechar
            </Button>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

export default CapturePage;
